export type NotificationType = "primary" | "success" | "error" | "warning" | "unsaved";

function assertiveNess(type: NotificationType) {
  switch (type) {
    case "error":
      return "assertive";
    case "warning":
      return "assertive";
    default:
      return "polite";
  }
}

export function notify(
  message: string,
  type: NotificationType,
  duration: number = 5000,
  id?: string,
) {
  document.body.insertAdjacentHTML(
    "beforeend",
    `
    <bb-notification
        id="${id || ""}"
        type="${type || ""}"
        position="bottom-left"
        dismissable="true"
        duration="${duration}"
        aria-live="${assertiveNess(type)}">
      ${message}
    </bb-notification>
    `,
  );
}
