<script lang="ts" context="module">
  import { orgHelp, orgSpeak } from "@nrk/origo";
  import { productFruitsInstance } from "services/productFruitsService";
  const SlackIcon: string =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="60 60 150 150" style="enable-background:new 0 0 270 270" xml:space="preserve"><g fill="#fff"><path d="M99.4 151.2c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9c0-7.1 5.8-12.9 12.9-12.9h12.9v12.9zM105.9 151.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9v-32.3zM118.8 99.4c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9 12.9 5.8 12.9 12.9v12.9h-12.9zM118.8 105.9c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H86.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3z"/><g><path d="M170.6 118.8c0-7.1 5.8-12.9 12.9-12.9 7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9h-12.9v-12.9zM164.1 118.8c0 7.1-5.8 12.9-12.9 12.9-7.1 0-12.9-5.8-12.9-12.9V86.5c0-7.1 5.8-12.9 12.9-12.9 7.1 0 12.9 5.8 12.9 12.9v32.3z"/></g><g><path d="M151.2 170.6c7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9-7.1 0-12.9-5.8-12.9-12.9v-12.9h12.9zM151.2 164.1c-7.1 0-12.9-5.8-12.9-12.9 0-7.1 5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9h-32.3z"/></g></g></svg>';
</script>

<button type="button" class="org-button">
  {@html orgHelp}
  <span class="org-sr">Hjelpemeny</span>
</button>
<bb-dropdown hidden>
  <h2 class="org-sr">Hjelp</h2>
  <p>Håper du liker Bildebanken!</p>
  <hr />
  <ul>
    <li>
      <a
        href="https://nrk.slack.com/archives/C07LE98MG9K"
        rel="noreferrer"
        target="_blank"
        class="org-button"
      >
        {@html SlackIcon}
        Hjelp og spørsmål på Slack (#bildebanken)
      </a>
      <button
        class="org-button feedback-button"
        disabled={!$productFruitsInstance}
        on:click|preventDefault={() => {
          $productFruitsInstance?.api.feedback.showModal();
        }}
      >
        {@html orgSpeak} Gi tilbakemelding ✨
      </button>
    </li>
  </ul>
</bb-dropdown>

<style>
  ul {
    padding-left: unset;
    margin-top: var(--org-small);
    margin-bottom: var(--org-xsmall);
  }

  li {
    list-style: none;
  }
  bb-dropdown {
    padding: var(--org-xsmall);
    width: fit-content;
  }

  .feedback-button {
    width: 100%;
    text-align: left;
    margin: 0;
  }

  p {
    margin: var(--org-small) var(--org-small);
  }
  hr {
    margin-top: var(--org-small);
    margin-bottom: 0;
  }
  li {
    margin-block: var(--org-xsmall);
  }
  a {
    width: 100%;
    text-align: left;
    margin: 0;
  }
</style>
