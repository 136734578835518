import type { Contact } from "bildebanken-model";
import { getAccessToken, getUsername } from "../auth/auth";
import { withRetry } from "../utils/retry";
import { getAuthorityProxyUrl } from "config";
import type { OrigoSuggestEvent } from "@nrk/origo-suggest/jsx";

export type LpAuthority = {
  emails: string[];
  name: string;
  officialName: string;
  uri: string;
  knownFor: string;
  sources: string[];
};

export async function replaceOrigoSuggestRequest(
  event: OrigoSuggestEvent & {
    detail: XMLHttpRequest & { responseJSON?: { suggestions?: unknown[] } };
    target: { ajax: string; input: { value: string } };
  },
) {
  event.preventDefault();
  let proxyUrl = event.target.ajax.replace("https://authority.nrk.no", getAuthorityProxyUrl());
  proxyUrl = proxyUrl.replace("{{value}}", event.target.input.value);
  const token = await getAccessToken();
  const req = event.detail;
  req.open("GET", proxyUrl);
  req.setRequestHeader("Authorization", `Bearer ${token}`);
  req.onload = () => {
    req.responseJSON = JSON.parse(req.responseText);
    event.target.dispatchEvent(new CustomEvent("suggest.ajax", { detail: req }));
  };
  req.send();
}

async function getCurrentUserAuthority(): Promise<LpAuthority | null> {
  const username = getUsername();
  if (!username) {
    return null;
  }

  return withRetry(async () => {
    const options = {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    };
    const url = new URL(
      `${getAuthorityProxyUrl()}/search/namecomplete?source=lp&size=1&alive=true`,
    );
    url.searchParams.append("text", username);
    const response = await fetch(url, options);

    if (!response.ok) {
      throw Error("Could not fetch authority data at " + url);
    }

    const body = await response.json();

    if (body.suggestions?.length === 1) {
      const suggestion = body.suggestions[0];
      if (
        suggestion &&
        typeof suggestion === "object" &&
        "emails" in suggestion &&
        "name" in suggestion &&
        "officialName" in suggestion &&
        "uri" in suggestion
      ) {
        return suggestion as LpAuthority;
      }
    }
    return null;
  });
}

export let currentUserAuthority: LpAuthority | null = null;

// We get this once for the whole application. If it fails, the user
// will have to reload the app to be able to use the feature.
// Is this the best trade off?
getCurrentUserAuthority()
  .then((ua) => (currentUserAuthority = ua))
  .catch(console.error);

export function authorityToContact(authority: LpAuthority): Contact {
  return {
    resId: authority.uri,
    title: authority.officialName || authority.name,
  };
}
