<script lang="ts">
  import { orgClose, orgMagnifier, orgMinus, orgPlus } from "@nrk/origo";
  import { isEmbedded } from "config";
  import { debounce } from "lodash-es";
  import { closePluginCallback } from "services/pluginMessages";
  import { searchByQuery, searchText } from "state/page";
  import { getCountFromQuery } from "state/queries";
  import { getKaleidoSearchQuery } from "state/queries/kaleido";
  import { getMimirSearchQuery } from "state/queries/mimir";

  import SearchIndicator from "./SearchIndicator.svelte";
  import SearchPills from "./SearchPills.svelte";
  import { imageSize, setImageSize } from "./imageSize";

  const mimirQuery = getMimirSearchQuery();
  const kaleidoQuery = getKaleidoSearchQuery();

  $: busy = $mimirQuery.isLoading || $mimirQuery.isRefetching;
  $: mimirCount = getCountFromQuery(mimirQuery);
  $: kaleidoCount = getCountFromQuery(kaleidoQuery);
  $: noHits = !busy && $mimirCount + $kaleidoCount === 0;

  let searchFieldFocus = false;

  const handleSearch = debounce((event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      searchByQuery(event.target.value);
    }
  }, 300);

  $: scale = $imageSize;
  $: sliderScale = scale * 5;
  const compactView = isEmbedded();

  $: setImageSize(sliderScale / 5);
</script>

<div class="search-bar">
  <form
    class="container"
    class:focus={searchFieldFocus}
    role="search"
    on:submit|preventDefault={() => $mimirQuery.refetch()}
    aria-label="Fritekstsøk"
  >
    <label>
      {@html orgMagnifier}
      <span class="org-sr">Søk etter bilde</span>
      <input
        type="text"
        name="search"
        autofocus
        placeholder="Søk etter bilde..."
        on:keyup|preventDefault={handleSearch}
        value={$searchText}
        on:focus={() => (searchFieldFocus = true)}
        on:blur={() => (searchFieldFocus = false)}
        data-testid="search-bar"
      />
      <SearchPills />
    </label>
  </form>

  <div class="count">
    {#if !noHits}
      <SearchIndicator />
    {/if}
  </div>

  {#if !compactView}
    <div class="image-size-adjuster">
      {@html orgMinus}
      <input
        class="org-range"
        aria-label="størrelse på bildene"
        type="range"
        bind:value={sliderScale}
        min="1"
        max="10"
        step="0.5"
      />
      {@html orgPlus}
    </div>
  {/if}

  {#if isEmbedded()}
    <button class="org-button" on:click={closePluginCallback} title="Avbryt">
      {@html orgClose}
    </button>
  {/if}
</div>

<style>
  .search-bar {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    gap: 30px;
    background: var(--org-color-gray-700);
    height: 60px;
  }

  .container {
    position: relative;
    display: flex;
    flex-flow: row;
    flex: 1;
    align-items: center;
    padding: 5px 0 5px 20px;
    background: #141517;
    border: solid 1px var(--org-color-shade-2);
    border-radius: 3px;
  }

  .count {
    width: 80px;
    flex-shrink: 0;
  }

  .container.focus {
    box-shadow: var(--org-shadow-focus);
  }

  .container > label {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .container > label > :global(svg) {
    font-size: 12px;
  }

  .container input {
    margin-left: 15px;
    background: transparent;
    color: var(--org-color-gray-400);
    font-family: "Roboto";
    font-size: 14px;

    letter-spacing: -0.2px;
    outline: none;
    border: 0;

    flex: 1;
    width: 100%;
    padding: 10px 0;
  }

  .image-size-adjuster {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .image-size-adjuster > :global(svg) {
    color: var(--org-color-shade-3);
  }

  .view-type-container {
    background: var(--org-color-shade-1);
    border-radius: 6px;
  }

  fieldset {
    display: flex;
    flex-flow: row;
    margin: 0;
    padding: 0;
    border: 0;
  }

  @media screen and (max-width: 1024px) {
    .count,
    .image-size-adjuster {
      display: none;
    }
  }
</style>
