<script lang="ts">
  import { orgAuthority, orgPlus } from "@nrk/origo";
  import {
    ROLES,
    getPhotographer,
    isPhotographer,
    type Contact,
    type Contributor,
    type Image,
  } from "bildebanken-model";
  import type { LpAuthority } from "services/authority";
  import { authorityToContact, currentUserAuthority, replaceOrigoSuggestRequest } from "services/authority";
  import { onMount } from "svelte";
  import { updateField, type EditImageActor } from "./editImageMachine";

  export let service: EditImageActor;
  export let image: Image;

  $: photographerContact = getPhotographer(image)?.contact;

  let suggestEl: HTMLElement;

  document.addEventListener("suggest.ajax.beforeSend", replaceOrigoSuggestRequest);
  onMount(() => {
    suggestEl?.addEventListener("suggest.select", onSelectPhotographer);
    return () => {
      suggestEl?.removeEventListener("suggest.select", onSelectPhotographer);
    };
  });

  function setPhotographer(contact: Contact) {
    const oldContributors = image.metadata.contributors ?? [];
    const newContributors: Contributor[] = oldContributors.filter((c) => !isPhotographer(c));

    newContributors.push({
      contact,
      role: { resId: ROLES.photographer },
    });

    updateField(service, "contributors", newContributors);
  }

  function onSelectPhotographer(event: CustomEvent & { target: { value: LpAuthority } }) {
    const value = event.target.value;
    if (value) {
      setPhotographer(authorityToContact(value));
    }
  }

  function onBlur(this: HTMLInputElement) {
    if (this.value !== photographerContact?.title) {
      setPhotographer({ title: this.value });
    }
  }

  function setCurrentUserAsPhotographer() {
    if (currentUserAuthority) {
      setPhotographer(authorityToContact(currentUserAuthority));
    }
  }
</script>

<label>
  Fotograf
  <span class="input-fields">
    <input
      class="org-input"
      type="text"
      value={photographerContact?.title ?? ""}
      placeholder=""
      autocomplete="off"
      on:blur={onBlur}
    />
    <bb-suggest query="size=5&source=lp&alive=true" type="agent" hidden bind:this={suggestEl} />
    {#if currentUserAuthority && photographerContact?.resId !== currentUserAuthority.uri}
      <button class="org-tag" on:click={setCurrentUserAsPhotographer}>
        {@html orgAuthority}
        {currentUserAuthority.name}
        {@html orgPlus}
      </button>
    {/if}
  </span>
</label>

<style>
  .input-fields {
    display: flex;
    align-items: baseline;
    gap: var(--org-small);
    flex-wrap: wrap;
  }

  .input-fields input {
    flex: 1;
    min-width: 200px;
  }
</style>
