<script lang="ts">
  import { orgClose, orgWarn } from "@nrk/origo";
  import { getQueryClientContext } from "@tanstack/svelte-query";
  import { useMachine } from "@xstate/svelte";

  import { getCurrentUser } from "services/api";
  import type { MimirSearchHit } from "services/searchHit";
  import DeleteImageListItem from "./DeleteImageListItem.svelte";
  import { deleteImagesMachine, type DeleteImageState } from "./deleteImagesMachine";

  export let selectedItems: MimirSearchHit[] = [];
  const currentUserId = getCurrentUser().id;
  const mappedItems = selectedItems.map((item) => item);
  const deletableCount = mappedItems.filter(
    (item) => item.createdBy === currentUserId && !item.metadata.publicId,
  ).length;

  const { state, send } = useMachine(
    deleteImagesMachine.withContext({
      selectedItems: mappedItems.filter(
        (item) => item.createdBy === currentUserId && !item.metadata.publicId,
      ),
      deletedItems: [],
      failedItems: [],
      errorMessages: {},
      queryClient: getQueryClientContext(),
    }),
  );

  function dialogHeader(state: DeleteImageState) {
    const many = state.context.selectedItems.length > 1;

    switch (state.value) {
      case "Confirming":
        return many ? "Slett bilder?" : "Slett bilde?";
      case "Deleting":
        return many ? "Slett bilder" : "Slett bilde";
      case "Failure":
        return "Sletting feilet";
      case "Cancelled":
        return "Avbrutt";
      case "Success":
        return many ? "Slett bilder" : "Slett bilde";
      default:
        return "Sletter";
    }
  }

  function dialogText(state: DeleteImageState) {
    const many = state.context.selectedItems.length > 1;

    switch (state.value) {
      case "Confirming":
        return (
          (many ? `${deletableCount} av dine bilder vil bli slettet.` : `Bildet vil bli slettet.`) +
          (somePublished ? ` Publiserte vil bli trukket tilbake.` : ``)
        );
      case "Deleting":
        return many ? "Sletter bilder…" : "Sletter bilde…";
      case "Failure":
        return "Sletting feilet";
      case "Success":
        return many ? "Bildene er slettet." : "Bildet er slettet.";
      case "Cancelled":
        return "Sletting avbrutt";
      default:
        return "";
    }
  }

  function onCancel() {
    if (!$state.done) {
      send({ type: "CANCEL" });
    }
  }

  const skippedItems = mappedItems.filter(
    (item) => item.createdBy !== currentUserId || item.metadata.publicId,
  );
  const somePublished = false;
</script>

<div class="wrapper">
  <header class="org-bar org-bar--primary" class:org-color-error={$state.matches("Failure")}>
    <h1>{dialogHeader($state)}</h1>
    <button class="org-button close-button" on:click={onCancel} data-for="close">
      {@html orgClose}
      <span class="org-sr">Lukk dialogen</span>
    </button>
  </header>
  <div class="message">
    {#if deletableCount >= 1}
      <p>{dialogText($state)}</p>
    {/if}
    {#if $state.matches("Confirming")}
      <ul>
        {#each $state.context.selectedItems as item}
          <DeleteImageListItem {item} />
        {/each}
      </ul>
      {#if skippedItems.length > 0}
        <p>Bildene under kan ikke slettes fordi de enten er publisert eller tilhører andre brukere.</p>
        <ul>
          {#each skippedItems as skippedItem}
            <DeleteImageListItem item={skippedItem} disabled={true} />
          {/each}
        </ul>
      {/if}
    {:else}
      <ul>
        {#each $state.context.selectedItems as item}
          <DeleteImageListItem {item} disabled={$state.context.deletedItems.includes(item.id)}>
            {#if $state.context.failedItems.includes(item.id)}
              <span>
                {@html orgWarn}
                {#if $state.context.errorMessages[item.id]}
                  <span class="error-message">{$state.context.errorMessages[item.id]}</span>
                {/if}
              </span>
            {/if}
          </DeleteImageListItem>
        {/each}
      </ul>
    {/if}
  </div>
  <div class="dialog-toolbar">
    <button class="org-button org-button--secondary" on:click={onCancel} data-for="close">
      {#if $state.matches("Deleting") || $state.matches("Confirming")}
        Avbryt
      {:else}
        Lukk
      {/if}
    </button>
    {#if $state.matches("Confirming")}
      <button
        class="org-button org-color-error"
        data-testid="delete-image-confirm"
        aria-disabled={!deletableCount}
        on:click={() => send("CONFIRM")}
      >
        Slett {$state.context.selectedItems.length > 1 ? "bilder" : "bilde"}
      </button>
    {/if}
  </div>
</div>

<style>
  .wrapper {
    min-height: 350px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
  }

  header {
    flex-shrink: 0;
  }

  .message {
    flex: 1;
    overflow-y: auto;
    padding: 0 var(--org-medium);
  }

  .dialog-toolbar {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    padding: var(--org-small);
    gap: var(--org-xsmall);
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  button.org-color-error:hover {
    background-color: var(--org-color-error);
    filter: brightness(1.1);
  }

  p {
    margin: var(--org-small) 0;
  }

  ul {
    margin: var(--org-medium) 0;
    padding: 0 var(--org-small);
  }

  .error-message {
    margin-left: var(--org-xsmall);
  }
</style>
