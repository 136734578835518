<script lang="ts">
  import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
  import AuthProvider from "./AuthProvider.svelte";
  import SearchProvider from "./SearchProvider.svelte";
  import StateProvider from "./StateProvider.svelte";
  import StyleProvider from "./StyleProvider.svelte";
  import OnlineManagerProvider from "./OnlineManagerProvider.svelte";
  import UserTrackingProvider from "./UserTrackingProvider.svelte";

  const queryClient = new QueryClient();
</script>

<StyleProvider>
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <StateProvider>
        <SearchProvider>
          <slot />
        </SearchProvider>
      </StateProvider>
    </QueryClientProvider>
    <UserTrackingProvider />
    <OnlineManagerProvider />
  </AuthProvider>
</StyleProvider>
