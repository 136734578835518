<script lang="ts">
  import type { OrigoSuggestAjaxEvent } from "@nrk/origo-suggest/jsx";
  import { debounce } from "lodash-es";
  import type { LpAuthority } from "services/authority";
  import { searchFilter, updateSearchFilter } from "state/page";
  import type { PhotographerFilter } from "state/params";
  import { orgWarn } from "@nrk/origo";
  import { currentUserAuthority, replaceOrigoSuggestRequest } from "services/authority";


  let suggestElement: HTMLElement;
  $: name = $searchFilter.photographer?.name;

  let noHits = false;
  let emptyQuery = false;

  document.addEventListener("suggest.ajax.beforeSend", replaceOrigoSuggestRequest);

  $: hideSuggestion = noHits || emptyQuery;

  const contactWithAuthority = !!currentUserAuthority;

  function suggestResponse(
    event: OrigoSuggestAjaxEvent & { detail: { responseJSON?: { suggestions?: unknown[] } } },
  ) {
    noHits = event.detail.responseJSON?.suggestions?.length === 0;
  }

  function addPhotographerToSearchFilter(event: CustomEvent & { target: { value: LpAuthority } }) {
    const data = event.target.value;

    const filter: PhotographerFilter = { name: data.name, id: data.uri };
    updateSearchFilter("photographer", filter);
  }

  function compareInputs(this: HTMLInputElement, event: Event) {
    emptyQuery = this.value.trim().length === 0;
    if (this.value !== name) {
      if (emptyQuery) {
        updateSearchFilter("photographer");
      } else if (["Enter", "Tab"].includes((event as KeyboardEvent).code)) {
        handleSearch(this.value);
      }
    }
  }

  const handleSearch = debounce((value: string) => {
    updateSearchFilter("photographer", { name: value });
  }, 300);
</script>

<div>
  <label>
    Fotograf
    <input
      class="org-input"
      type="text"
      placeholder="Søk på fotograf"
      value={name ?? ""}
      on:input={compareInputs}
      on:keydown={compareInputs}
    />
    <bb-suggest
      class:hide={hideSuggestion}
      query="source=lp"
      type="agent"
      on:suggest.select={addPhotographerToSearchFilter}
      on:suggest.ajax={suggestResponse}
      bind:this={suggestElement}
    ></bb-suggest>
  </label>
<!--   {#if !contactWithAuthority}
    <span class="org-warning-copy"
      >{@html orgWarn} Klarer ikke hente fra autoritetsregisteret. Er du på NRK-nettverket eller VPN?</span
    >
  {/if} -->
</div>

<style>
  bb-suggest {
    overflow-x: hidden;
    background-color: var(--color-surface-primary);
    color: var(--color-text-primary);
    width: 280px;
    & small {
      display: none;
    }
  }

  div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .hide {
    display: none;
  }

  .org-warning-copy {
    background-color: var(--org-color-warning);
    color: var(--org-color-black);
    border-radius: 3px;
    box-sizing: border-box;
    font-size: var(--org-font-small, 12px);
    font-weight: 400;
    line-height: 1;
    padding: 0.5em;
    max-width: fit-content;
  }
</style>
