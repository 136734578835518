<svelte:options immutable />

<script lang="ts">
  import { onMount } from "svelte";
  import { formatRelativeDate } from "utils/fns";

  export let date: Date | undefined | null;
  export let timeZone: string | undefined = undefined;
  export let sortBy: "mediaCreatedOn" | "createdOn";

  let relativeDate: string;

  const updateTimeStampInterval = 10 * 1000; // 10s
  const title = `${
    sortBy === "mediaCreatedOn" ? "Fotografert " : "Lastet opp "
  }${date?.toLocaleDateString("nb-NB", {
    dateStyle: "medium",
    timeZone,
  })} ${date?.toLocaleTimeString("no-NB", {
    timeStyle: "short",
    timeZone,
  })}`;

  function updateDate(date?: Date | null) {
    if (date) {
      relativeDate = formatRelativeDate(date, timeZone) || "-";
    }
  }

  onMount(() => {
    let interval: number;
    try {
      updateDate(date);
      interval = window.setInterval(() => {
        updateDate(date);
      }, updateTimeStampInterval);
    } catch (err) {
      console.error("date:", date, err);
    }

    return () => clearInterval(interval);
  });

  // because the date can change depending on context
  $: updateDate(date);
</script>

{#if date}
  <span {title}>{relativeDate}</span>
{/if}
