<script lang="ts">
  import { onlineManager, focusManager } from "@tanstack/svelte-query";
  import { notify } from "services/notifications";
  import { onlineState } from "../../state/page";
 
  onlineManager.setEventListener(() => {
    function handleOnline() {
      if ($onlineState === "OFFLINE") {
        document.getElementById("offline")?.remove();
        notify("Du er nå tilbake på nett", "success", 3000);
        onlineState.set("ONLINE");
        focusManager.setFocused(true);
      }
    }
    function handleOffline() {
      notify(
        "Det ser ut som du har mistet tilgang til Internett. Bildebanken vil ikke fungere uten nett",
        "warning",
        50000,
        "offline",
      );
      onlineState.set("OFFLINE");
      focusManager.setFocused(undefined);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });
</script>
