<script lang="ts">
  import type { Image } from "bildebanken-model";
  import { publishAndSelect } from "components/Plugin/publishAndSelect.js";
  import type { SearchHit } from "services/searchHit";

  export let item: Image;
  export let label = "Bruk";
  export let primary = false;
</script>

<button
  class="org-button"
  class:org-button--primary={primary}
  class:org-button--secondary={!primary}
  aria-label="Bruk bildet"
  title="Bruk bildet"
  on:click={() => publishAndSelect(item)}
>
  {label}
</button>
