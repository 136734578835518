import type { SelectedImage } from "components/Plugin/imageTypes";
import { writable } from "svelte/store";

export type Config = {
  release: string;
  /** What host to use to serve frontend and assets, also as base for links in app */
  bildebankenHost: string;
  /** Specify if different from bildebankenHost */
  apiHost?: string;
  navigationBehavior: NavigationBehavior;
  authenticationFlow: AuthenticationFlow;
  bildeRobotHost?: string;
  /**
   * Explicitly control if to run application in embedded mode, for dev.
   * If not set the value be checked dynamically
   */
  embedded?: boolean;
  bildebankenGroupId: string;
  /** If injected here it will override defaults, mainly for dev usage */
  kaleido?: KaleidoConfig;
};

declare const CONFIG_INPUT: Config;

const CONFIG = { ...CONFIG_INPUT };

// to initialize to window.location.origin, if necessary
setApiHost(CONFIG_INPUT.apiHost ?? CONFIG_INPUT.bildebankenHost);

/**
 * Should navigation in the app be of type
 *   - NONE: no effect on browser history or location (default)
 *   - HISTORY_ONLY: only use history API (back/forward works)
 *   - LOCATION: Use history API and update window.location.
 */
export type NavigationBehavior = "NONE" | "REPLACE_ALWAYS" | "LOCATION";
export type AuthenticationFlow = "REDIRECT" | "POPUP" | "CALLBACK";

type KaleidoConfig = {
  username: string;
  gfx: string;
  environment: "dev" | "production" | "stage";
};

export type ImageSelectionCallback = (image: SelectedImage) => void;

export type CloseCallback = () => void;

const kaleidoFallbackConfig: Record<string, KaleidoConfig> = {
  "bildebanken.nrk.no": {
    username: "bildebanken",
    gfx: "https://gfx.nrk.no",
    environment: "production",
  },
  "bildebanken-beta.nrk.no": {
    username: "bildebanken-beta",
    gfx: "https://gfx.nrk.no",
    environment: "production",
  },
  "bildebanken-stage.nrk.no": {
    username: "bildebanken-stage",
    gfx: "https://gfx-stage.nrk.no",
    environment: "stage",
  },
  "bildebanken-dev.nrk.no": {
    username: "bildebanken-dev",
    gfx: "https://gfx-dev.nrk.no",
    environment: "dev",
  },
};

export function getKaleidoConfig(): KaleidoConfig | undefined {
  if (!CONFIG.kaleido) {
    // Relevant in Standalone
    if (location.host in kaleidoFallbackConfig) {
      return kaleidoFallbackConfig[location.host];
    }

    // Relevant in Plugin
    const bbHost = getBildebankenHost().host;
    if (bbHost in kaleidoFallbackConfig) {
      console.log(bbHost, kaleidoFallbackConfig[bbHost]);
      return kaleidoFallbackConfig[bbHost];
    }
    return kaleidoFallbackConfig["bildebanken-stage.nrk.no"];
  }

  // In dev mode where these are injected via Vite
  return CONFIG.kaleido;
}

export function getKaleidoEnvironment(): "stage" | "dev" | "production" | undefined {
  return getKaleidoConfig()?.environment;
}

export function setNavigationBehavior(value: NavigationBehavior): void {
  CONFIG.navigationBehavior = value;
}

export function getNavigationBehavior(): NavigationBehavior {
  return CONFIG.navigationBehavior;
}

export function getRelease(): string {
  return CONFIG.release;
}

export function getBildebankenHost() {
  if (CONFIG.bildebankenHost === "/") {
    return new URL(window.location.origin + "/");
  }

  return new URL(CONFIG.bildebankenHost);
}

export function setBildebankenHost(host: string) {
  CONFIG.bildebankenHost = host;
}

export function getApiUrl(): string {
  if (CONFIG.apiHost) {
    return new URL("api/v1", CONFIG.apiHost).href;
  } else {
    return new URL("api/v1", getBildebankenHost()).href;
  }
}

export function setApiHost(host: string): void {
  if (!host.endsWith("/")) host += "/";
  CONFIG.apiHost = host === "/" ? window.location.origin + "/" : host;
}

export function getKaleidoProxyUrl(): string {
  if (CONFIG.apiHost) {
    return new URL("/api/kaleido/", CONFIG.apiHost).href;
  } else {
    return new URL("/api/kaleido/", getBildebankenHost()).href;
  }
}
export function getNtbProxyUrl(): string {
  if (CONFIG.apiHost) {
    return new URL("/api/ntb/", CONFIG.apiHost).href;
  } else {
    return new URL("/api/ntb/", getBildebankenHost()).href;
  }
}

export function getAuthorityProxyUrl(): string {
  if (CONFIG.apiHost) {
    return new URL("/api/authority", CONFIG.apiHost).href;
  } else {
    return new URL("/api/authority", getBildebankenHost()).href;
  }
}
export function getBildeRobotHost(): string | undefined {
  return CONFIG.bildeRobotHost;
}

export function isEmbedded(): boolean {
  if (CONFIG.embedded === undefined) {
    return window.self !== window.top;
  }

  return CONFIG.embedded;
}

export function getBildebankenGroupId(): string {
  return CONFIG.bildebankenGroupId;
}

export function getStillsGroupId(): string {
  return "nrk:5fe68715-aa63-44f3-b8c4-bb56cfb088a1";
}

export type TokenCallback = () => Promise<string>;

export type AuthenticationOptions =
  | {
      flow: "POPUP" | "REDIRECT";
    }
  | {
      flow: "CALLBACK";
      account: UserAccount;
      tokenCallback: TokenCallback;
    };

let _tokenCallback: TokenCallback;

export type UserAccount = {
  username?: string;
  name?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  groups?: string[];
};

export function setupAuthentication(options: AuthenticationOptions) {
  CONFIG.authenticationFlow = options.flow;
  if (options.flow === "CALLBACK") {
    _tokenCallback = options.tokenCallback;
    _account = options.account;
  }
}
export function getAuthenticationFlow(): AuthenticationFlow {
  return CONFIG.authenticationFlow;
}
export function getTokenUsingCallback(): Promise<string> {
  return _tokenCallback();
}

let _account: UserAccount;

export function getPluginHostAccount(): UserAccount {
  return _account;
}

export function setPluginHostAccount(account: UserAccount): void {
  _account = account;
}

export const showCloseButton = writable(false);
let _closeCallback: CloseCallback | undefined = undefined;

export function setCloseCallback(callback: CloseCallback | undefined) {
  _closeCallback = callback;
  showCloseButton.set(callback !== undefined);
}

export function getCloseCallback(): CloseCallback | undefined {
  return _closeCallback;
}

export function getMountElement(): HTMLElement | null {
  return document.getElementById("bildebanken-mount-element");
}
