<script lang="ts">
  import {
    calculateCreditLine,
    getRightsMarker,
    isPersonInImage,
    isPhotographer,
    isUploader,
    type Contributor,
    type Image,
  } from "bildebanken-model";
  import RightsIndicator from "components/Common/RightsIndicator.svelte";
  import CopyWrapper from "../Common/CopyWrapper.svelte";
  import TechnicalMetadata from "./TechnicalMetadata.svelte";

  import { orgDownload, orgLocked, orgUnlocked } from "@nrk/origo";
  import { first } from "lodash-es";
  import { isEmbedded } from "../../config";
  import { bytesToSize, hasImageCreatedDate } from "../../utils/fns";
  import { onMount } from "svelte";
  import type { ImageSource } from "state/page";

  export let image: Image;
  export let itemSource: ImageSource;
  export let kaleidoApiIsAccessible: boolean = true;

  const contactTitle = (contributor: Contributor) => contributor.contact.title;
  const rootStyle = `--inactive-color: #949494;`; // Contrat ratio 4:52 to (--var-org-color-gray-700)

  $: photographers = image.metadata.contributors?.filter(isPhotographer);
  $: photographerName = photographers?.length && contactTitle(first(photographers)!);
  $: personsInImage = image.metadata.contributors?.filter(isPersonInImage) || [];
  $: uploader = image.metadata.contributors?.filter(isUploader)?.pop();
  $: createdOn = new Date(image.createdOn);

  async function checkOnline(url: string) {
    try {
      const response = await fetch(url, { method: "GET" });
      return response.ok;
    } catch (error) {
      return false;
    }
  }

  onMount(async () => {
    if (image.highRes && itemSource === "Kaleido") {
      kaleidoApiIsAccessible = await checkOnline(image.highRes);
    }
  });
  export let externalImage = false;

  $: creditLine = calculateCreditLine(image.metadata);
  $: mediaCreatedOn = image.mediaCreatedOn ? new Date(image.mediaCreatedOn) : undefined;
  $: rights = getRightsMarker(image);
  $: rightsDescription =
    rights === "Free" ? "Fri bruk i NRK" : image.metadata.rights?.rightsHolder?.contact?.title;

  $: landscape = dimensions && dimensions.width > dimensions.height;
  $: originalFile = image.originalFileName?.replace(`_${image.id}`, "");

  const technical = image.technicalMetadata?.formData;
  $: dimensions = technical?.technical_image_width &&
    technical?.technical_image_height && {
      width: technical?.technical_image_width,
      height: technical?.technical_image_height,
    };

  $: isPrivate = image.visibleTo?.level === "owner";

  // Temporary work-around to display correct image capture time. See issue DS-3234.
  // Only compensate images with image creation date.
  // Update: Also adjust timezone for NTB pictures, as they are without timezone (local time)
  $: imageCreatedDateAvailable = hasImageCreatedDate(image);
  $: ntbPicture = window.location.href.includes("/images/ntb/");

  $: timeZone = imageCreatedDateAvailable && !ntbPicture ? "UTC" : undefined;

  $: futureMediaCreatedOnDate =
    image?.metadata?.mediaCreatedOn && new Date(image.metadata.mediaCreatedOn) > new Date();
</script>

<div class="wrapper" style={rootStyle}>
  <dl>
    <dt>Tittel</dt>
    <dd>{image.metadata.title || ""}</dd>
    <dt>Beskrivelse</dt>
    <dd>{image.metadata.description || ""}</dd>
    {#if image.metadata.altText}
      <dt>Alternativ tekst</dt>
      <dd>{image.metadata.altText}</dd>
    {/if}
    <dt>Bruksvilkår</dt>
    <dd>
      <RightsIndicator rightsMarker={rights} />
      {!image.metadata.rights
        ? "Ukjent"
        : [rightsDescription, image.metadata.rights?.note].filter(Boolean).join(" • ")}
    </dd>
    <dt>Kreditering</dt>
    <dd class="credit">
      <CopyWrapper>{creditLine || ""}</CopyWrapper>
    </dd>
    {#if photographerName && !creditLine?.includes(photographerName)}
      <dt>Fotograf</dt>
      <dd>
        {image.metadata.contributors?.filter(isPhotographer)?.map(contactTitle)}
      </dd>
    {/if}
    {#if isPrivate}
      <dt>Synlighet</dt>
      <dd>
        <span class="icon">{@html isPrivate ? orgLocked : orgUnlocked}</span>
        {isPrivate ? "Kun eier" : "Alle"}
      </dd>
    {/if}
    {#if image.metadata.location || image.metadata.country}
      <dt>Sted</dt>
      <dd>{[image.metadata.location, image.metadata.country].filter(Boolean).join(", ")}</dd>
    {/if}
    <dt>{imageCreatedDateAvailable ? "Fotografert" : "Lastet opp"}</dt>
    <dd>
      {#if mediaCreatedOn}
        {mediaCreatedOn?.toLocaleDateString("no-NB", { timeZone })}
        <span
          title={mediaCreatedOn?.toLocaleTimeString("no-NB", { timeZone, timeStyle: "medium" })}
        >
          {mediaCreatedOn?.toLocaleTimeString("no-NB", { timeZone, timeStyle: "short" })}
        </span>
      {:else}
        –
      {/if}
    </dd>
  </dl>
  {#if imageCreatedDateAvailable && futureMediaCreatedOnDate && !ntbPicture}
    <span class="org-warning">Fremtidig fotografert dato</span>
  {/if}
  {#if !externalImage}
    <button class="org-button expand">Detaljer</button>
    <bb-expand hidden={isEmbedded()}>
      <TechnicalMetadata {image} />
    </bb-expand>
  {/if}
  <button class="org-button expand">Referanser</button>
  <bb-expand hidden={isEmbedded() || externalImage}>
    <dl>
      {#if !externalImage}
        <dt>Publiseringer</dt>
        <dd>
          <span class="inactive">Ingen</span>
        </dd>
      {/if}
      <dt>Original</dt>
      <dd class="flex original">
        {#if dimensions}
          <span title="Dimensjoner">
            {dimensions.width} × {dimensions.height}
          </span>
        {/if}
        {#if image.mediaType}
          <span class="tag" title="Filformat">
            {image.mediaType.split("/")?.pop()?.toUpperCase()}
          </span>
        {/if}
        {#if image.mediaSize}<span title="Filstørrelse">{bytesToSize(image.mediaSize)}</span>{/if}

        {#if image.highRes}
          <a
            aria-disabled={!kaleidoApiIsAccessible}
            class="org-button download"
            href={image.highRes}
            title={`Last ned original`}
          >
            {@html orgDownload} Last ned
          </a>
          {#if !kaleidoApiIsAccessible}
            <p class="org-warning">Du må koble til VPN for å kunne laste ned bildet</p>
          {/if}
        {/if}
        <span class="filename" title="Filnavn"> {originalFile}</span>
      </dd>
      <dt>Opprettet</dt>
      <dd class="flex">
        <span>
          {#if uploader}
            <span title="Opplaster">{uploader.contact.title}</span>
          {/if}
          {#if image.metadata.sourceSystem}
            (<span title="Kildesystem">{image.metadata.sourceSystem}</span>)
          {/if}
        </span>
        {#if createdOn}
          <span title={createdOn.toLocaleTimeString()}>
            {createdOn.toLocaleDateString("no-NB")}
          </span>
        {/if}
      </dd>
      <dt>Unik ID</dt>
      <dd>
        <CopyWrapper title="Kopier ID">
          <span class="monospace">{image.id}</span>
        </CopyWrapper>
      </dd>
      {#if image.metadata.publicId}
        <dt>Kaleido-ID</dt>
        <dd>
          <CopyWrapper title="Kopier ID">
            <span class="monospace">{image.metadata.publicId}</span>
          </CopyWrapper>
        </dd>
      {/if}
    </dl>
  </bb-expand>
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .monospace {
    font-family: monospace;
    font-size: 0.8rem;
  }

  .inactive {
    color: var(--inactive-color);
  }

  :global(dd.flex) {
    display: flex;
    gap: 1.5em;
  }

  .flex.original {
    flex-wrap: wrap;
  }

  .original > :not(:last-child) {
    white-space: nowrap;
  }

  bb-expand {
    margin-top: 0.5em;
    background-color: var(--org-color-gray-700);
  }

  .org-button.expand {
    padding-left: 0;
  }

  button.expand {
    text-align: left;
  }

  .icon {
    font-size: 0.8rem;
  }

  a {
    color: unset;
    text-decoration: none;
  }

  a.download {
    margin: -5px 0;
  }

  a.download:hover {
    text-decoration: none;
  }

  .tag {
    background-color: var(--org-color-gray-500);
    color: var(--org-color-gray-900);
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
  }

  .filename {
    opacity: 0;
    transition: 0.25s;
    color: var(--org-color-gray-400);
  }

  dd:hover .filename {
    opacity: 1;
  }

  :global(dl) {
    display: grid;
    grid-template-columns: 120px 1fr;
  }

  :global(dt) {
    font-weight: 700;
  }

  :global(dt),
  :global(dd) {
    border-bottom: solid 1px var(--org-color-shade-2);
    margin: 0;
    padding: var(--org-small) 0;
    line-height: 1.5;
    overflow: hidden;
  }
</style>
